:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._paddingTop-1481558307{padding-top:var(--space-4);}
:root ._paddingBottom-1481558307{padding-bottom:var(--space-4);}
:root ._display-flex{display:flex;}
:root ._justifyContent-center{justify-content:center;}
:root ._alignItems-center{align-items:center;}
:root ._backgroundColor-1775178828{background-color:var(--backgroundPositiveSubtle);}
:root ._flexDirection-row{flex-direction:row;}
:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-1481558338{margin-left:var(--space-3);}
:root ._fontSize-14px{font-size:14px;}
:root ._lineHeight-20px{line-height:20px;}
:root ._fontWeight-bold{font-weight:bold;}
:root ._color-19330913{color:var(--contentPrimary);}