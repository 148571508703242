:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._paddingRight-1481558245{padding-right:var(--space-6);}
:root ._paddingLeft-1481558245{padding-left:var(--space-6);}
:root ._paddingTop-1481558152{padding-top:var(--space-9);}
:root ._paddingBottom-1481558152{padding-bottom:var(--space-9);}
:root ._backgroundColor-43815239{background-color:var(--color-49);}